<template>
  <section class="emails-area">
    <transition name="zoom-fade" mode="out-in">
      <router-view ref="view" />
    </transition>
  </section>
</template>

<script>
// import { mapState } from 'vuex';
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  beforeCreate() {},
  created() {},
  mounted() {}
};
</script>
